h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: 600;
}
.w-md {
    min-width: 110px;
}
.row > * {
    position: relative;
}
.collapsing {
    transition: height 0.15s ease;
}
a {
    text-decoration: none !important;
}
.badge {
    --gyg-badge-font-weight: 600;
}
.font-size-18 {
    font-size: 1.125rem;
}
.pagination {
    --gyg-pagination-bg: var(--gyg-secondary-bg);
}
.page-link:focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--gyg-primary-rgb), 0.25);
}
.page-item.disabled {
    cursor: no-drop !important;
}
.todo-mayuscula {
    text-transform: uppercase;
}

.border-sales {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.4rem;
    margin: 0.5rem 0.1rem 0 0;
    padding-top: 0.9rem;
    padding-bottom: 0.7rem;
}
.title-items {
    position: absolute;
    top: -13px;
    left: 0;
    font-weight: 600;
    font-size: 1rem;
    width: auto;
}
.add-item-sales {
    background-color: $color-primary;
    height: 1rem;
    padding: 0rem 0.2rem 0 0.2rem;
    cursor: pointer;
    text-align: center;
    border-radius: 50%;
    i {
        color: #fff;
    }
}
.import-items {
    background-color: $color-primary;
    height: 1rem;
    padding: 0rem 0.3rem 0 0.3rem;
    cursor: pointer;
    text-align: center;
    border-radius: 50%;
    i {
        color: #fff;
    }
}

.square-switch {
    display: block;
    input {
        display: none;
    }
    input + label,
    input + label:after {
        border-radius: 4px;
    }
    input + label {
        font-weight: 500;
        text-align: center;
        transition: all 0.1s ease-in-out;
        background-color: #ced4da;
        background-image: none;
        cursor: pointer;
        display: inline-block;
        font-size: 1em;
        height: 24px;
        line-height: 1;
        padding: 0.16667rem;
        position: relative;
        width: 56px;
    }
    input + label:before {
        color: #212529;
        content: attr(data-off-label);
        display: block;
        font-family: inherit;
        font-size: 12px;
        line-height: 21px;
        margin: 3px;
        min-width: 1.66667rem;
        overflow: hidden;
        position: absolute;
        right: 1px;
        top: -2px;
        font-weight: 500;
        text-align: center;
        transition: all 0.1s ease-in-out;
    }
    input + label:after {
        background-color: #eff2f7;
        box-shadow: none;
        content: "";
        height: 20px;
        left: 3px;
        position: absolute;
        top: 2px;
        transition: all 0.1s ease-in-out;
        width: 20px;
    }
    input:checked + label {
        background-color: $color-primary;
    }
    input:checked + label:before {
        color: #fff;
        content: attr(data-on-label);
        left: 3px;
        right: auto;
    }
    input:checked + label:after {
        background-color: #eff2f7;
        left: 33px;
    }
}

.nav-guia-remision {
    .nav-link {
        &.active {
            background-color: lighten($color-primary, 47.8); //#d2f1e8
            color: $color-primary !important;
        }
        &.tab-error {
            background-color: var(--gyg-danger-bg-subtle) !important;
            color: var(--gyg-color-danger) !important;
        }
        background-color: var(--gyg-body-bg);
        font-weight: 600;
    }
}

.logo-inscription {
    width: 50%;
}

.whatsapp-wrap {
    position: fixed;
    right: 20px;
    bottom: 50px;
    z-index: 99999;
    a {
        background: #25d366;
        width: 60px;
        padding: 1em;
        height: 60px;
        border-radius: 50%;
        display: block;
        float: right;
        img {
            width: 100%;
        }
    }
}

@media only screen and (min-width: 676px) {
    .whatsapp-wrap {
        right: 40px;
        bottom: 50px;
    }
}

@media only screen and (max-width: 427px) {
    .logo-inscription {
        width: 70%;
    }
}

@media only screen and (max-width: 676px) {
    .whatsapp-wrap {
        display: none;
    }
}
