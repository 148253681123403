label {
    font-weight: 600;
    margin-bottom: 0.5rem;
}
.form-control {
    background-color: var(--gyg-secondary-bg);
    border-color: var(--gyg-border-color-translucent);
    font-size: 0.9rem;
    &:focus {
        border-color: lighten($color-primary, 21);
        box-shadow: 0 0 0 0.25rem rgba($color-primary, 0.25);
        background-color: var(--gyg-secondary-bg);
    }
}

.form-control-sm {
    font-size: .7875rem;
    min-height: calc(1.5em + 0.5rem + var(--gyg-border-width)*2);
}

.form-control.is-invalid{
    padding-right: calc(1.5em + 0.94rem);
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
    background-image: url('../../assets/svg/danger.svg');
}

.form-group__error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: var(--gyg-form-invalid-color);
    p{
        padding: 0;
        margin: 0;
    }
}

.form-control:disabled{
    background-color: #e9ecef;
}
.form-select{
    --gyg-form-select-bg-img: url('../../assets/svg/select.svg');
    padding: 0.275rem 2.25rem 0.275rem 0.75rem;
}
.form-select.is-invalid{
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem)!important;
}

.form-control[readonly] {
    background-color: #e9ecef;
    cursor: no-drop;
    opacity: 1;
    &:focus{
        box-shadow: none;
    }
}