.twitter-bs-wizard .twitter-bs-wizard-nav {
    position: relative;
}

.nav-pills {
    --gyg-nav-pills-link-active-bg: $color-primary;
}

.twitter-bs-wizard .twitter-bs-wizard-nav:before {
    background-color: #f1f5f7;
    background-color: var(--gyg-border-color);
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    top: 26px;
    width: 100%;
}

.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link.active {
    background-color: initial;
    color: var(--gyg-body-color) !important;
}
.twitter-bs-wizard .twitter-bs-wizard-nav .step-number {
    background-color: #fff;
    border: 2px solid $color-primary;
    border-radius: 50%;
    color: $color-primary;
    display: inline-block;
    height: 38px;
    line-height: 34px;
    position: relative;
    text-align: center;
    width: 38px;
}
.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link.active .step-number {
    background-color: $color-primary;
    color: #fff;
}
.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link.active .step-number i{
    color: #fff!important;
    font-weight: 700;
    font-size: 1.3rem;
}
.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link .step-title {
    display: block;
    font-weight: 600;
    margin-top: 8px;
}
.twitter-bs-wizard-tab-content {
    min-height: 262px;
    padding-top: 24px;
}

.twitter-bs-wizard .twitter-bs-wizard-pager-link {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    padding-top: 24px;
}
.twitter-bs-wizard .twitter-bs-wizard-pager-link li {
    display: inline-block;
}

.twitter-bs-wizard .twitter-bs-wizard-pager-link li.next {
    float: right;
}

.twitter-bs-wizard .twitter-bs-wizard-pager-link li a {
    background-color: $color-primary;
    border-radius: 0.25rem;
    color: #fff;
    display: inline-block;
    padding: 0.47rem 0.75rem;
    &:hover {
        color: #fff;
    }
}
.twitter-bs-wizard .twitter-bs-wizard-pager-link li.disabled a {
    background-color: lighten($color-primary, 10%);
    cursor: not-allowed;
}