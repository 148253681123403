// Fonts
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap");
@import "@fortawesome/fontawesome-free/css/all.css";
@import "remixicon/fonts/remixicon.css";

//DatePicker
@import 'flatpickr/dist/themes/material_green.css';

//Tooltip
@import 'react-tooltip/dist/react-tooltip.css';

// Bootstrap variables
$prefix: "gyg-";

// Then import Bootstrap
@import "bootstrap/scss/bootstrap";

//Variables
@import "variables";

//Theme
@import "theme/html";
@import "theme/loading";
@import "theme/sweet-alert";
@import "theme/simplebar";
@import "theme/form";
@import "theme/btn";
@import "theme/sidebar";
@import "theme/header";
@import "theme/cards";
@import "theme/table";
@import "theme/modal";
@import "theme/select2";
@import "theme/datepicker";
@import "theme/pasos";