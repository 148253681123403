.flatpickr-months,
.flatpickr-weekdays {
  background-color: $color-primary !important;
}

span.flatpickr-weekday {
  color: #fff !important;
  font-weight: 500 !important;
}

.flatpickr-current-month {
  .flatpickr-monthDropdown-months {
    &:hover {
      background-color: transparent !important;
    }
  }
}

.flatpickr-am-pm,
.numInput,
.numInputWrapper {
  &:focus,
  &:hover {
    background-color: transparent !important;
  }
}

.flatpickr-weekdays {
  height: 36px !important;
  border-bottom: 1px solid #f5f6f8;
}

.flatpickr-day {
  color: #495057;
  &:hover,
  &:focus {
    background-color: rgba(#f5f6f8, 1) !important;
  }
  &.today {
    border-color: $color-primary !important;
    background-color: rgba($color-primary, 0.1);
    &:hover,
    &:focus {
      color: #343a40 !important;
      background-color: rgba($color-primary, 0.15) !important;
    }
  }
  &.selected {
    background-color: $color-primary !important;
    border-color: $color-primary !important;
    color: #fff !important;
  }

  &.flatpickr-disabled,
  &.flatpickr-disabled:hover,
  &.prevMonthDay,
  &.nextMonthDay,
  &.notAllowed,
  &.notAllowed.prevMonthDay,
  &.notAllowed.nextMonthDay {
    color: #adb5bd !important;
  }

  &.inRange,
  &.prevMonthDay.inRange,
  &.nextMonthDay.inRange,
  &.today.inRange,
  &.prevMonthDay.today.inRange,
  &.nextMonthDay.today.inRange,
  &:hover,
  &.prevMonthDay:hover,
  &.nextMonthDay:hover,
  &:focus,
  &.prevMonthDay:focus,
  &.nextMonthDay:focus {
    background-color: #f5f6f8 !important;
    border-color: #f5f6f8;
  }
  &.inRange {
    box-shadow:
      -5px 0 0 #f5f6f8,
      5px 0 0 #f5f6f8;
  }
  &.selected,
  &.startRange,
  &.endRange,
  &.selected.inRange,
  &.startRange.inRange,
  &.endRange.inRange,
  &.selected:focus,
  &.startRange:focus,
  &.endRange:focus,
  &.selected:hover,
  &.startRange:hover,
  &.endRange:hover,
  &.selected.prevMonthDay,
  &.startRange.prevMonthDay,
  &.endRange.prevMonthDay,
  &.selected.nextMonthDay,
  &.startRange.nextMonthDay,
  &.endRange.nextMonthDay {
    background-color: $color-primary !important;
    border-color: $color-primary !important;
    color: #fff !important;
  }
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 lighten($color-primary, 30) !important;
}

.flatpickr-months {
  .flatpickr-prev-month,
  .flatpickr-next-month,
  .flatpickr-month {
    color: rgba(#fff, 0.9) !important;
    fill: rgba(#fff, 0.9) !important;
    //background-color: $color-primary !important;
  }
}
.flatpickr-months .flatpickr-month, .flatpickr-current-month .flatpickr-monthDropdown-months,span.flatpickr-weekday{
  background-color: $color-primary !important;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: $color-primary !important;
}
.flatpickr-monthDropdown-month {
  color: rgba($black, 0.8);
}
.flatpickr-current-month {
  input.cur-year {
    &[disabled],
    &[disabled]:hover {
      color: rgba(#fff, 0.9) !important;
    }
  }
}

.flatpickr-time {
  input,
  .flatpickr-time-separator,
  .flatpickr-am-pm {
    color: #343a40 !important;
  }
}

.flatpickr-calendar {
  background-color: #f5f6f8 !important;
  box-shadow:
    1px 0 0 #f5f6f8,
    -1px 0 0 #f5f6f8,
    0 1px 0 #f5f6f8,
    0 -1px 0 #f5f6f8,
    0 3px 13px rgba(0, 0, 0, 0.08) !important;
  &.hasTime {
    .flatpickr-time {
      border-top: 1px solid #f5f6f8 !important;
    }
  }
}

.flatpickr-months {
  border-radius: 5px 5px 0px 0px;
  .flatpickr-prev-month,
  .flatpickr-next-month {
    &:hover {
      svg {
        fill: rgba(#fff, 0.9) !important;
      }
    }
  }
}

.flatpickr-input[disabled="true"] {
  background-color: #e9ecef;
  cursor: no-drop !important;
}

.form-control.flatpickr-input[readonly] {
  background-color: #fff;
  cursor: pointer !important;
}
