.btn {
    --gyg-btn-padding-x: 0.75rem;
    --gyg-btn-padding-y: 0.47rem;
    --gyg-btn-font-size: 0.9rem;
}
.btn-group-lg>.btn, .btn-lg {
    --gyg-btn-padding-y: .5rem;
    --gyg-btn-padding-x: 1rem;
    --gyg-btn-font-size: 1.125rem;
    --gyg-btn-border-radius: var(--gyg-border-radius-lg);
}
.btn-group-sm > .btn,
.btn-sm {
    --gyg-btn-padding-y: 0.25rem;
    --gyg-btn-padding-x: 0.5rem;
    --gyg-btn-font-size: 0.7875rem;
}

.btn-primary {
    --gyg-btn-color: var(--gyg-white);
    --gyg-btn-bg: var(--gyg-color-primary);
    --gyg-btn-border-color: var(--gyg-color-primary);
    --gyg-btn-hover-color: var(--gyg-white);
    --gyg-btn-hover-bg: #{shade-color($color-primary, 10%)};
    --gyg-btn-hover-border-color: #{shade-color($color-primary, 10%)};
    --gyg-btn-focus-shadow-rgb: var(--gyg-color-primary-rgb);
    --gyg-btn-active-color: var(--gyg-btn-hover-color);
    --gyg-btn-active-bg: #{shade-color($color-primary, 20%)};
    --gyg-btn-active-border-color: #{shade-color($color-primary, 20%)};
    --gyg-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --gyg-btn-disabled-bg: var(--gyg-color-primary);
    --gyg-btn-disabled-border-color: var(--gyg-color-primary);
}
.btn-group{
    width: 100%;
}
.btn-group>input:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}